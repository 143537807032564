<template>
  <transition
    ref="floating"
    :class="classes"
    :style="floatingStyles"
    @before-enter="onBeforeEnter"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @enter-cancelled="onEnterCancelled"
    @before-leave="onBeforeLeave"
    @leave="onLeave"
    @after-leave="onAfterLeave"
    @leave-cancelled="onLeaveCancelled"
  >
    <slot />
  </transition>
</template>

<script setup>
import { computePosition } from '@floating-ui/vue'
import { ref } from 'vue'

const props = defineProps({
  enterActiveClass: {
    type: String,
    default: '',
  },
  enterFromClass: {
    type: String,
    default: '',
  },
  enterToClass: {
    type: String,
    default: '',
  },
  leaveActiveClass: {
    type: String,
    default: '',
  },
  leaveFromClass: {
    type: String,
    default: '',
  },
  leaveToClass: {
    type: String,
    default: '',
  },
  reference: {
    type: [Object, null],
    required: true,
  },
  options: {
    type: Object,
    default: {},
  },
})

const classes = ref([])
const floating = ref(null)
const floatingStyles = ref('')

function onBeforeEnter(el) {
  classes.value = [props.enterFromClass, props.enterActiveClass]

  computePosition(props.reference, el, props.options).then(({ x, y }) => {
    floatingStyles.value = {
      left: `${x}px`,
      top: `${y}px`,
    }

    classes.value = [props.enterActiveClass, props.enterToClass]
  })
}

function onEnter(el, done) {}

function onAfterEnter(el) {
  classes.value = [props.leaveFromClass, props.leaveActiveClass]
}

function onEnterCancelled(el) {
  classes.value = [props.enterFromClass, props.enterActiveClass]
}

function onBeforeLeave(el) {
  classes.value = [props.leaveFromClass, props.leaveActiveClass]
}
function onLeave(el, done) {
  classes.value = [props.leaveToClass, props.leaveActiveClass]
}
function onAfterLeave(el) {
  classes.value = []
}
function onLeaveCancelled(el) {
  classes.value = [props.leaveFromClass, props.leaveActiveClass]
}
</script>
